import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

export default function App() {
  return (
      <Router>
        <div className="inherit-height">
          {/*<ul>*/}
          {/*  <li>*/}
          {/*    <Link to="/">Welcome</Link>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <Link to="/part-one">Part One</Link>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <Link to="/part-two">Part Two</Link>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <Link to="/part-three">Part Three</Link>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <Link to="/finish">Finish</Link>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <Link to="/snapshot">Snapshot</Link>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <Link to="/upload">Upload</Link>*/}
          {/*  </li>*/}
          {/*</ul>*/}

          {/*<hr />*/}

          {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
          <Routes>
            <Route exact path="/" element={<Welcome />} />
            <Route path="/part-one" element={<PartOne />} />
            <Route path="/part-two" element={<PartTwo />} />
            <Route path="/part-three" element={<PartThree />} />
            <Route path="/finish" element={<Finish />} />
            <Route path="/snapshot" element={<Snapshot />} />
            <Route path="/upload" element={<Upload />} />
          </Routes>
        </div>
      </Router>
  );
}

function Welcome() {
  return (
      <div className="welcome-page">
        <img className="logo" src="bcglogo.webp"/>
        <h1 className="title">Welcome to our loan application process!</h1>
        <p>
          A simple three page process will walk you through providing all the
          relevant files and information that we need to evaluate your loan
          request and determine how we can best help you and your company.
        </p>
        <p>
          To streamline the process, please have the following materials on-hand
          before proceeding:
        </p>
        <ul>
          <li>résumés for company principals</li>
          <li>two months bank statements</li>
          <li>corporate inception documents</li>
          <li>ownership breakdown and org structure docs</li>
          <li>two years federal tax filings</li>
          <li>personal financial statements</li>
          <li>previous and current year financial statements</li>
          <li>current insurance policies</li>
        </ul>
        <div className="get-started">
          <Link to="/part-one">
            <button>Get Started!</button>
          </Link>
        </div>
        <div className="info">
          missing some items or they don't apply to your company?
          No problem! Just add notes to that section and tell us about it. :)
        </div>
      </div>
  )
}

function PartOne() {
  return (
      <div className="part-page">
        <h1>Part 1 of 3: Company Information</h1>
        <p>
          The prompts below will help us to understand your company its people.
        </p>
        <ol>
          <li>
            <h1>History & Background</h1>
            <p>
              Please upload files which fully describe the history and background of
              your company, along with résumés for all principals.
            </p>
            <FileUploadComponent
              files={[
                { name: 'history.docx' },
                { name: 'background.docx' },
                { name: 'Frank\'s Résumé.docx' },
                { name: 'Albert\'s Résumé.docx' },
              ]}
            />
            <div className="questions">
              <h2>Specific Questions</h2>
              <ul>
                <li>
                  <Question value={'1996'} text={'In what year was your company originally founded?'} />
                </li>
              </ul>
            </div>
          </li>
          <li>
            <h1>Origination Documents</h1>
            <p>
              Please share with us your articles of incorporation, certificate of
              formation, and DBA filings. (if any)
            </p>
            <FileUploadComponent />
          </li>
          <li>
            <h1>Ownership and Organization</h1>
            <p>
              Please upload files which represent your CAP table or ownership breakdown
              as well as the current organizational structure.
            </p>
            <FileUploadComponent
              files={[
                { name: 'CAP Table.xlsx' },
                { name: 'org_chart.PDF' }
              ]}
            />
          </li>
        </ol>
        <div className="footer">
          <Link to="/">
            <button className="nav left">Go Back</button>
          </Link>
          <Link to="/part-two">
            <button className="nav right">Continue</button>
          </Link>
        </div>
      </div>
  )
}

function PartTwo() {
  return (
    <div className="part-page">
      <h1>Part 2 of 3: Revenue</h1>
      <p>This page collects data regarding your revenue streams.</p>
      <ol>
        <li>
          <h1>2021 Financial Statement</h1>
          <p>Please include year end balance sheets, P&L, and cash flow statements.</p>
          <FileUploadComponent
            files={[
              { name: 'accounts.xlsx' },
              { name: 'cashflow.PDF' }
            ]}
          />
          <div className="questions">
            <h2>Specific Questions</h2>
            <ul>
              <li>
                <Question
                    text={'What was your total revenue for calendar year 2021?'}
                    value={'$14,682,913.52'}
                />
              </li>
              <li>
                <Question
                    text={'What were your total business expenses for calendar year 2021?'}
                    value={'$10,659,173.19'}
                />
              </li>
            </ul>
          </div>
        </li>
        <li>
          <h1>2022 Interim Financial Statement</h1>
          <p>Share with us a financial statement for the year-to-date.</p>
          <FileUploadComponent
            files={[
              { name: '2022.xlsx' }
            ]}
          />
          <div className="questions">
            <h2>Specific Questions</h2>
            <ul>
              <li>
                <Question
                    text={'What is your total revenue so far for calendar year 2022?'}
                    value={'$3,642,133.00'}
                />
              </li>
            </ul>
          </div>
        </li>
        <li>
          <h1>Bank Statements</h1>
          <p>Please provide your bank statements for the past two months.</p>
          <FileUploadComponent
              files={[
                { name: 'February 2022.PDF' },
                { name: 'March 2022.PDF' },
              ]}
          />
          <div>
            <h2>Specific Questions</h2>
            <ul>
              <li>
                <Question
                    text={'What is your total cash on hand at this moment?'}
                    value={'$1,019,442.74'}
                />
              </li>
            </ul>
          </div>
        </li>
      </ol>
      <div className="footer">
        <Link to="/part-one">
          <button className="nav left">Go Back</button>
        </Link>
        <Link to="/part-three">
          <button className="nav right">Continue</button>
        </Link>
      </div>
    </div>
  )
}

function PartThree() {
  return (
    <div className="part-page">
      <h1>Part 3 of 3: Finances & Insurance</h1>
      <p>Finally, this page collects financial and insurance data.</p>
      <ol>
        <li>
          <h1>Federal Tax Filings</h1>
          <p>Please upload past two years federal (ONLY) tax returns.</p>
          <FileUploadComponent
            files={[
              { name: '2021 tax.PDF' },
              { name: '2020 tax.PDF' },
            ]}
          />
        </li>
        <li>
          <h1>Personal Financial Information</h1>
          <p>Upload a current personal bank statement.</p>
          <FileUploadComponent
            files={[
              { name: 'My Bank.PDF' }
            ]}
          />
        </li>
        <li>
          <h1>Insurance Information</h1>
          <p>TODO: how do we decribe the insurance documents we desire?</p>
          <FileUploadComponent
          />
        </li>
      </ol>
      <div className="footer">
        <Link to="/part-two">
          <button className="nav left">Go Back</button>
        </Link>
        <Link to="/finish">
          <button className="nav right">Finish</button>
        </Link>
      </div>
    </div>
  )
}

function Finish() {
  return (
      <div className="finish-page">
        <h1>Thank You!</h1>
        <p>Your application is complete! We will contact you shortly to discuss next steps. ❤️</p>
        <div className="info">
          remember - if you ever want to update or change this information,
          you can use the link in your email to return at any time
        </div>
      </div>
  )
}

function Snapshot() {
  return (
      <div>
        <h2>Snapshot</h2>
      </div>
  )
}

function Upload() {
  return (
      <div>
        <h2>Upload</h2>
      </div>
  )
}

function FileUploadComponent(props) {
  return (
      <div className="file-upload-component">
        {
          props?.files?.length ?
            <ul>
              {
                props.files.map( file =>
                  <li>
                    <FileIcon name={file.name} />
                  </li>
                )
              }
            </ul>
            :
            <div className="warning">no files added yet...</div>
        }
        <Link to="/upload">
          <button>Add File</button>
        </Link>
        <button>Add Additional Information</button>
      </div>
  )
}

function FileIcon(props) {
  return (
      <div class="file-icon">
        <div className="icon"></div>
        <span>{props.name}</span>
      </div>
  );
}

function Question(props) {
  return (
      <div className="question">
        <span>{props.text}</span>
        <input type="text" value={props.value}/>
      </div>
  )
}
